import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
const languageCode = window.languageCode || 'fr';


i18next.use(initReactI18next)
    .use(Backend)
    .use(languageDetector)
    .init({
        fallbackLng: "fr",
        lng: languageCode,
        resources: {
            en: {
                translation: require("../locales/en/translation.json"),
            },
            fr: {
                translation: require("../locales/fr/translation.json"),
            },
        },
    }
);


export default i18next;

